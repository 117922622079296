import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useQuery, gql } from "@apollo/client";
import { Image, ThemeContext } from "grommet";
import { getSubdomain } from "../../../../components/utils/getSubdomain";
import { useLocale } from "@/lib/hooks";

const CLOVERLEAF_LOGO_BANNER_FALLBACK = "https://s3.amazonaws.com/cloverleaf-app-publicfiles/Cloverleaf-Banner.png";
const CLOVERLEAF_LOGO_AVATAR_FALLBACK = "https://s3.amazonaws.com/cloverleaf-app-publicfiles/Cloverleaf.png";

const organizationLogoQuery = gql`
  query organizationLogo($subdomain: String) {
    organizationLogo(subdomain: $subdomain) @connection(key: "organizationLogo")
    organizationBanner(subdomain: $subdomain) @connection(key: "organizationBanner")
  }
`;

function Logo({
  avatar = false,
  src = undefined,
  width = undefined,
  ...props
}) {
  const { t } = useLocale();
  let imgWidth;
  let logoUrl = src;

  const router = useRouter();
  const theme = React.useContext(ThemeContext);

  // Pulls out subdomain from the router or from the browser APIs on the client
  const subdomain = router?.query?.subdomain || getSubdomain();

  const { data } = useQuery(organizationLogoQuery, {
    variables: { subdomain },
    fetchPolicy: "cache-and-network",
    skip: logoUrl,
  });

  if (width) {
    imgWidth = theme?.global?.size[width] || width;
  }

  if (!logoUrl) {
    // Avatar logo either exists or it will fallback to the cloverleaf logo
    const logoAvatar = data?.organizationLogo || CLOVERLEAF_LOGO_AVATAR_FALLBACK;

    // Banner logo either exists or it will fallback to the cloverleaf logo
    const logoBanner = data?.organizationBanner || CLOVERLEAF_LOGO_BANNER_FALLBACK;

    logoUrl = avatar ? logoAvatar : logoBanner;
  }

  return (
    <Image
      aria-hidden="true"
      alt={t("logo-alt")}
      fill={false}
      src={logoUrl}
      width={imgWidth}
      {...props}
    />
  );
}

Logo.propTypes = {
  avatar: PropTypes.bool,
  src: PropTypes.string,
  width: PropTypes.string,
};

export { Logo };
