import styled from "styled-components";
import { lightenDarkenColor } from "../../utils";
import {
  GREEN,
  DARK_GREEN,
  DARKEST_GREEN,
  RED,
  DARK_RED,
  WHITE,
  LIGHT_GREY,
  DARK_GREY,
  GREY,
  BLACK,
  CONDENSED_FONT,
  BLUE,
} from "../../utils/theme";

const primary = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: GREEN,
    hover: DARK_GREEN,
    active: DARKEST_GREEN,
  },
  border: {
    default: DARK_GREEN,
    hover: DARKEST_GREEN,
    active: DARK_GREEN,
  },
};

const primaryBlue = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: BLUE,
    hover: "#00697c",
    active: BLUE,
  },
  border: {
    default: BLUE,
    hover: "#00697c",
    active: "#00697c",
  },
};

const primaryInverse = {
  color: {
    default: GREEN,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: WHITE,
    hover: DARK_GREEN,
    active: DARKEST_GREEN,
  },
  border: {
    default: LIGHT_GREY,
    hover: DARKEST_GREEN,
    active: DARK_GREEN,
  },
};

const outline = {
  color: {
    default: DARKEST_GREEN,
    hover: DARK_GREEN,
    active: DARKEST_GREEN,
  },
  background: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  border: {
    default: GREEN,
    hover: DARK_GREEN,
    active: DARKEST_GREEN,
  },
};

const secondary = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: GREY,
    hover: BLACK,
    active: GREEN,
  },
  border: {
    default: GREY,
    hover: BLACK,
    active: GREEN,
  },
};

const dark = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: BLACK,
    hover: BLACK,
    active: GREEN,
  },
  border: {
    default: BLACK,
    hover: BLACK,
    active: GREEN,
  },
};

const negative = {
  color: {
    default: RED,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: WHITE,
    hover: RED,
    active: DARK_RED,
  },
  border: {
    default: LIGHT_GREY,
    hover: DARK_RED,
    active: RED,
  },
};

const negativePrimary = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: RED,
    hover: DARK_RED,
    active: DARK_RED,
  },
  border: {
    default: DARK_RED,
    hover: RED,
    active: DARK_RED,
  },
};

const clear = {
  color: {
    default: GREEN,
    hover: GREEN,
    active: WHITE,
  },
  background: {
    default: "transparent",
    hover: "transparent",
    active: GREEN,
  },
  border: {
    default: GREY,
    hover: GREEN,
    active: DARK_GREEN,
  },
};

const clearAndWhite = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: "transparent",
    hover: "transparent",
    active: GREEN,
  },
  border: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
};

const disabled = {
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: GREY,
    hover: GREY,
    active: GREY,
  },
  border: {
    default: DARK_GREY,
    hover: DARK_GREY,
    active: DARK_GREY,
  },
};

const custom = color => ({
  color: {
    default: WHITE,
    hover: WHITE,
    active: WHITE,
  },
  background: {
    default: color,
    hover: lightenDarkenColor(color, -0.1),
    active: lightenDarkenColor(color, -0.2),
  },
  border: {
    default: lightenDarkenColor(color, -0.1),
    hover: lightenDarkenColor(color, -0.2),
    active: lightenDarkenColor(color, -0.1),
  },
});

const color = (props, state = "default") => {
  if (props.disabled) {
    return disabled.color[state];
  }
  if (props.primary) {
    return primary.color[state];
  }
  if (props.primaryBlue) {
    return primaryBlue.color[state];
  }
  if (props.primaryInverse) {
    return primaryInverse.color[state];
  }
  if (props.outline) {
    return outline.color[state];
  }
  if (props.secondary) {
    return secondary.color[state];
  }
  if (props.negative) {
    return negative.color[state];
  }
  if (props.negativePrimary) {
    return negativePrimary.color[state];
  }
  if (props.clear) {
    return clear.color[state];
  }
  if (props.clearAndWhite) {
    return clearAndWhite.color[state];
  }
  if (props.dark) {
    return dark.color[state];
  }
  if (props.color) {
    return custom(props.color).color[state];
  }

  return WHITE;
};

const background = (props, state = "default") => {
  if (props.disabled) {
    return disabled.background[state];
  }
  if (props.primary) {
    return primary.background[state];
  }
  if (props.primaryBlue) {
    return primaryBlue.background[state];
  }
  if (props.primaryInverse) {
    return primaryInverse.background[state];
  }
  if (props.outline) {
    return outline.background[state];
  }
  if (props.secondary) {
    return secondary.background[state];
  }
  if (props.negative) {
    return negative.background[state];
  }
  if (props.negativePrimary) {
    return negativePrimary.background[state];
  }
  if (props.clear) {
    return clear.background[state];
  }
  if (props.clearAndWhite) {
    return clearAndWhite.background[state];
  }
  if (props.dark) {
    return dark.background[state];
  }
  if (props.color) {
    return custom(props.color).background[state];
  }

  return WHITE;
};

const border = (props, state = "default") => {
  if (props.disabled) {
    return disabled.border[state];
  }
  if (props.primary) {
    return primary.border[state];
  }
  if (props.primaryBlue) {
    return primaryBlue.border[state];
  }
  if (props.primaryInverse) {
    return primaryInverse.border[state];
  }
  if (props.outline) {
    return outline.border[state];
  }
  if (props.secondary) {
    return secondary.border[state];
  }
  if (props.negative) {
    return negative.border[state];
  }
  if (props.negativePrimary) {
    return negativePrimary.border[state];
  }
  if (props.clear) {
    return clear.border[state];
  }
  if (props.dark) {
    return dark.border[state];
  }
  if (props.color) {
    return custom(props.color).border[state];
  }

  return WHITE;
};

const boxShadow = ({ shadow }) => (shadow ? "0px 1px 2px rgba(0, 0, 0, 0.25)" : "none");

const StyledButton = styled.button`
  background-color: ${background};
  border: ${props => (props.outline ? "2px solid" : "1px solid")};
  border-radius: 5px;
  border-color: ${border};
  color: ${color};
  display: inline-block;
  font-family: ${CONDENSED_FONT};
  font-size: ${props => props.fontSize || (props.small ? "11px" : "14px")};
  letter-spacing: 1px;
  text-align: center;
  height: ${props => (props.small ? "25px" : "unset")};
  min-height: ${props => (props.small ? "25px" : "40px")};
  white-space: ${props => (props.small ? "nowrap" : "inherit")};
  padding: ${props => (props.small ? "0 10px" : "0 20px")};
  outline: 0;
  margin: ${props => (props.block ? "0 auto" : "")};
  width: ${props => (props.block ? "100%" : "fit-content")};
  text-transform: uppercase;
  user-select: none;
  box-shadow: ${props => boxShadow(props)};

  &:hover {
    cursor: ${props => (props.disabled ? "" : "pointer")};
    background-color: ${props => background(props, "hover")};
    border-color: ${props => border(props, "hover")};
    color: ${props => color(props, "hover")};
  }

  &:active {
    background-color: ${props => background(props, "active")};
    border-color: ${props => border(props, "active")};
    color: ${props => color(props, "active")};
  }

  &:disabled, &:disabled:active, &:disabled:hover {
    background-color: ${props => (props.outline ? WHITE : GREY)};
    border-color: ${DARK_GREY};
    color: ${props => (props.outline ? GREY : WHITE)};
  }
`;

export default StyledButton;
