import { publicRuntimeConfig } from "./config";

const sensitiveParams = ["email", "token"];

function sanitizeSearchParams(search = "") {
  const baseUrl = typeof window !== "undefined" ? window.location.origin : publicRuntimeConfig.CLOVERLEAF_CLIENT_URL;
  const searchParams = typeof window !== "undefined" ? search || window.location.search : new URLSearchParams(search);

  const parsedUrl = new URL(searchParams, baseUrl);

  sensitiveParams.forEach(param => parsedUrl.searchParams.delete(param));

  return parsedUrl.search;
}

export { sanitizeSearchParams };
