import { gql, useQuery } from "@apollo/client";
import { Configuration } from "@/components/utils/constants";

export const GET_DIRECTORY_DATA_QUERY = gql`
  query getDirectoryData {
    currentUser {
      id
      isLead
      isCandidate
      organization {
        id
        isCoach
        directoryConfig: configurationSetting(configurationId: "${Configuration.DIRECTORY}") {
          enabled
        }
      }
    }
  }
`;

function hasDirectoryEnabled(user) {
  return Boolean(
    user?.organization?.directoryConfig?.enabled &&
    !user?.organization?.isCoach &&
    !user?.isLead &&
    !user?.isCandidate
  );
}

function useShowDirectory() {
  const { data } = useQuery(GET_DIRECTORY_DATA_QUERY);
  const currentUser = data?.currentUser;

  return hasDirectoryEnabled(currentUser);
}

export { useShowDirectory, hasDirectoryEnabled };
