import React from "react";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { LoadingIndicator, Message } from "@/cloverleaf-ui/components";
import { TutorialDrop } from "../components/TutorialDrop";
import { EnergyRhythmQuestion, ENERGY_RHYTHM_QUESTION_TYPE } from "./Question";
import { useLocale } from "@/lib/hooks";

const GET_ENERGY_RHYTHM_SCORES_QUERY = gql`
  query getEnergyRhythmScores {
    currentUser {
      id
      scores {
        energyRhythm {
          title {
            id
          }
        }
      }
    }
  }
`;

function EnergyRhythmExampleQuestion({
  onChange = () => undefined,
}) {
  const { t } = useLocale();

  const [value, setValue] = React.useState();
  const [walkthrough, setWalkthrough] = React.useState(-1);
  const [refVisible, setRefVisible] = React.useState(false);

  const { loading, data } = useQuery(GET_ENERGY_RHYTHM_SCORES_QUERY);

  const targetRef = React.useRef();

  React.useEffect(() => {
    if (!refVisible) {
      return;
    }

    setWalkthrough(0);
  }, [refVisible]);

  const radioQuestion = React.useMemo(() => ({
    number: "abcd-efgh-ijkl-mnop",
    type: ENERGY_RHYTHM_QUESTION_TYPE.CHOICE,
    prompt: t("energy-rhythm.instructions.radio-question-example"),
    options: [
      {
        label: t("energy-rhythm.instructions.example.morning"),
        value: 1,
      },
      {
        label: t("energy-rhythm.instructions.example.afternoon"),
        value: 2,
      },
      {
        label: t("energy-rhythm.instructions.example.night"),
        value: 3,
      },
    ],
  }), [t]);

  const sliderQuestion = React.useMemo(() => ({
    number: "klmn-opqr-stuv-wxyz",
    type: ENERGY_RHYTHM_QUESTION_TYPE.TIME,
    prompt: t("energy-rhythm.instructions.slider-question-example"),
    options: [
      {
        label: t("energy-rhythm.instructions.example.5am"),
        value: 5,
      },
      {
        label: t("energy-rhythm.instructions.example.6am"),
        value: 6,
      },
      {
        label: t("energy-rhythm.instructions.example.7am"),
        value: 7,
      },
      {
        label: t("energy-rhythm.instructions.example.8am"),
        value: 8,
      },
      {
        label: t("energy-rhythm.instructions.example.9am"),
        value: 9,
      },
      {
        label: t("energy-rhythm.instructions.example.10am"),
        value: 10,
      },
      {
        label: t("energy-rhythm.instructions.example.11am"),
        value: 11,
      },
      {
        label: t("energy-rhythm.instructions.example.12pm"),
        value: 12,
      },
    ],
  }), [t]);

  const tutorial = [
    {
      tip: t("energy-rhythm.instructions.radio-question-tooltip"),
      question: radioQuestion,
    },
    {
      tip: t("energy-rhythm.instructions.slider-question-tooltip"),
      question: sliderQuestion,
    },
  ];

  const handleOnChange = (value) => {
    setValue(value);

    // Tutorial Specific
    const nextStep = walkthrough + 1;

    if (nextStep < tutorial.length) {
      return setWalkthrough(nextStep);
    }

    setWalkthrough(nextStep);

    return onChange(value);
  };

  if (loading) {
    return (
      <LoadingIndicator />
    );
  }

  if (data?.currentUser?.scores?.energyRhythm?.title?.id) {
    return (
      <Message
        kind="error"
        label={t("already-taken")}
        margin={{ vertical: "small" }}
      />
    );
  }

  return (
    <React.Fragment>
      <EnergyRhythmQuestion
        ref={el => {
          targetRef.current = el;
          setRefVisible(true);
        }}
        question={tutorial[walkthrough]?.question || sliderQuestion}
        onChange={handleOnChange}
        selected={value}
      />
      {refVisible && tutorial[walkthrough] && targetRef && (
        <TutorialDrop target={targetRef.current}>
          {tutorial[walkthrough].tip}
        </TutorialDrop>
      )}
    </React.Fragment>
  );
}

EnergyRhythmExampleQuestion.propTypes = {
  onChange: PropTypes.func,
};

export { EnergyRhythmExampleQuestion };
