import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import { SixteenTypesExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/16types/ExampleQuestion";
import { SixteenTypesQuestion } from "@/cloverleaf-ui/templates/Assessments/16types/Question";
import { CulturePulseExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/culture-pulse/ExampleQuestion";
import { CulturePulseQuestion } from "@/cloverleaf-ui/templates/Assessments/culture-pulse/Question";
import { DiscExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/disc/ExampleQuestion";
import { DiscQuestion } from "@/cloverleaf-ui/templates/Assessments/disc/Question";
import { EnergyRhythmExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/energy-rhythm/ExampleQuestion";
import { EnergyRhythmQuestion } from "@/cloverleaf-ui/templates/Assessments/energy-rhythm/Question";
import { EnneagramExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/enneagram/ExampleQuestion";
import { EnneagramQuestion } from "@/cloverleaf-ui/templates/Assessments/enneagram/Question";
import { InstinctiveDrivesExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/instinctive-drives/ExampleQuestion";
import { InstinctiveDrivesQuestion } from "@/cloverleaf-ui/templates/Assessments/instinctive-drives/Question";
import { MotivatingValuesExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/motivating-values/ExampleQuestion";
import { MotivatingValuesQuestion } from "@/cloverleaf-ui/templates/Assessments/motivating-values/Question";
import { StrengthscopeQuestion } from "@/cloverleaf-ui/templates/Assessments/strengthscope/Question";
import {
  CulturePulseAnswers,
  DiscAnswers,
  EnneagramAnswers,
  InstinctiveDriveAnswers,
  MotivatingValuesAnswers,
  SixteenTypesAnswers,
  ViaAnswers
} from "@/cloverleaf-ui/templates/Assessments/utils";
import { ViaExampleQuestion } from "@/cloverleaf-ui/templates/Assessments/via/ExampleQuestion";
import { ViaQuestion } from "@/cloverleaf-ui/templates/Assessments/via/Question";
import {
  ASSESSMENT,
  INSIGHTS_DISCOVERY_URL,
  STRENGTHSFINDER_URL
} from "../../components/utils/constants";
import { currentUserQuery, currentUserFragment } from "./currentUser";
import { discScoreFragment, personalityScoreFragment } from "./scores";
import { GET_CURRENT_USER_ASSESSMENTS_CONFIG_QUERY } from "@/components/v2/MyDashboard/GettingStartedBanner/GettingStartedBanner";

// Update the client-side currentUser scores with the response.
// Accepts a mutation name, which is the key for the response data
// and assessmentName, which is the apollo key under currentUser.scores
export const updateCurrentUserScore = (mutationName, assessmentName) =>
  (store, res) => {
    try {
      const score = get(["data", mutationName, "score"], res);
      const result = store.readQuery({ query: currentUserQuery });
      // CurrentUser may not exist, i.e. the user loaded an assessment directly.
      if (result.currentUser) {
        result.currentUser.scores[assessmentName] = {
          ...getOr({}, ["currentUser", "scores", assessmentName], result),
          ...score,
        };
        store.writeQuery({
          query: currentUserQuery,
          data: result,
        });
      }
    }
    catch (e) {
      /**
       * Try store.readQuery:
       * "...readQuery [throws] a MissingFieldError exception to report missing fields."
       * - https://www.apollographql.com/docs/react/caching/cache-interaction/#readquery
       *
       * currentUserQuery stateData is not always in/readable from cache. In such an instance,
       * store.readQuery throws an invariant violation, "missing field stateData..."
       *
       * The user experience does not appear to be impacted by this error, but we should catch and ignore.
       * Aside, even when this fails the scores are refreshed by apollo, from either the mutation result
       * or network, so this code might be unnecessary...
       */
      console.warn("Could not read or write new score to cache", e);
    }
  };

export const withSubmitPersonality = graphql(gql`
  mutation submitPersonality($input: PersonalityAssessmentInput!) {
    setPersonalityAssessment(input: $input) {
      score {
        ...PersonalityScoreFragment
      }
    }
  }
  ${personalityScoreFragment}
`, {
  props: ({ mutate }) => ({
    submitPersonality: input => mutate({
      variables: { input },
      update: updateCurrentUserScore("setPersonalityAssessment", "personality"),
      refetchQueries: [{
        query: GET_CURRENT_USER_ASSESSMENTS_CONFIG_QUERY,
      }],
    }),
  }),
});

export const withSubmitDisc = graphql(gql`
  mutation submitDisc($input: DiscAssessmentInput!) {
    setDiscAssessment(input: $input) {
      user {
        id
        scores {
          disc {
            ...DiscScoreFragment
          }
        }
      }
    }
  }
  ${discScoreFragment}
`, {
  props: ({ mutate }) => ({
    submitDisc: input => mutate({
      variables: { input },
    }),
    refetchQueries: [{
      query: GET_CURRENT_USER_ASSESSMENTS_CONFIG_QUERY,
    }]
  }),
});

export const strengthsFinderCodeQuery = gql`
  query strengthsFinderCode {
    strengthsFinderCode {
      id
      code
      isRedeemed
    }
  }
`;

export const submitStrengthsFinderMutation = gql`
  mutation submitStrengthsFinder($input: StrengthsFinderAssessmentInput!) {
    setStrengthsFinderAssessment(input: $input) {
      score {
        title {
          id
          name
          secondaryName
          description
        }
        traits {
          id
          name
          nameKey
          secondaryName
          secondaryNameKey
          description
          order
        }
      }
    }
  }
`;

export const withSubmitVia = graphql(gql`
  mutation submitVia($input: ViaAssessmentInput!) {
    setViaAssessment(input: $input) {
      score {
        title {
          id
          name
          secondaryName
          description
        }
        traits {
          id
          name
          nameKey
          secondaryName
          secondaryNameKey
          description
          order
        }
      }
    }
  }
`, {
  props: ({ mutate }) => ({
    submitVia: input => mutate({
      variables: { input },
      update: updateCurrentUserScore("setViaAssessment", "via"),
      refetchQueries: [{
        query: GET_CURRENT_USER_ASSESSMENTS_CONFIG_QUERY,
      }],
    }),
  }),
});

export const getSixteenTypesQuestionsQuery = gql`
  query getPersonalityQuestions($name: AssessmentName!) {
    assessment(name: $name ) {
      questions {
        number
        ...on PersonalityQuestion {
            prompt
            answerA
            answerB
        }
      }
    }
  }
`;

export const getDiscQuestionsQuery = gql`
  query getDiscQuestions($name: AssessmentName!) {
    assessment(name: $name ) {
      questions {
        number
        ... on DiscQuestion {
          answers {
            description
            descriptionKey
          }
        }
      }
    }
  }
`;

export const getEnneagramQuestionsQuery = gql`
  query getEnneagramQuestions($name: AssessmentName!) {
    assessment(name: $name) {
      questions {
        number
        ... on PersonalityQuestion {
          prompt
          answerA
          answerB
        }
      }
    }
  }
`;

const getEnergyRhythmQuestionsQuery = gql`
  query getEnergyRhythmQuestions {
    newAssessment(name: ${ASSESSMENT.ENERGYRHYTHM}) {
      title
      name
      type
      questions {
        id
        questionId
        answerId
        ... on EnergyRhythmQuestion {
          prompt
          name
          type
          answer
          options {
            label
            value
          }
        }
      }
    }
  }
`;

const getInstinctiveDrivesQuestionsQuery = gql`
  query getInstinctiveDrivesQuestions {
    newAssessment(name: ${ASSESSMENT.INSTINCTIVEDRIVES}) {
      title
      name
      type
      questions {
        id
        questionId
        answerId
        ... on InstinctiveDrivesQuestion {
          prompt
          answerText1
          answer1
          answerText2
          answer2
          answerText3
          answer3
          answerText4
          answer4
        }
      }
    }
  }
`;

const getViaQuestionsQuery = gql`
  query getViaQuestions {
    assessment(name: ${ASSESSMENT.VIA}) {
      questions {
        number
        ... on ViaQuestion {
          prompt
        }
      }
    }
  }
`;

const getMotivatingValuesQuestionsQuery = gql`
  query getMotivatingValuesQuestions {
    assessment(name: ${ASSESSMENT.MOTIVATINGVALUES}) {
      questions {
        number
        ... on MotivatingValuesQuestion {
          subject
          options
        }
      }
    }
  }
`;

const getCulturePulseQuestionsQuery = gql`
  query getCulturePulseQuestions {
    assessment(name: ${ASSESSMENT.CULTURE}) {
      questions {
        number
        ... on CulturePulseQuestion {
          labelLeft
          labelRight
        }
      }
    }
  }
`;

const strengthscopeAssessmentQuestionsQuery = gql`
  query StrengthscopeAssessment {
    newAssessment(name: ${ASSESSMENT.STRENGTHSCOPE}) {
      id: name
      name
      title
      questions {
        id
        questionId
        answerId
        ... on StrengthscopeQuestion {
          prompt
          answers {
            id
            prompt
          }
        }
      }
    }
  }
`;

export const withCulturePulseQuestions = graphql(gql`
  query getCulturePulseQuestions($name: AssessmentName!) {
    assessment(name: $name ) {
      questions {
        number
        ... on CulturePulseQuestion {
          labelLeft
          labelRight
        }
      }
    },
  }
`, {
  options: {
    variables: { name: ASSESSMENT.CULTURE },
  },
});

export const withCurrentUserAndConfiguration = graphql(gql`
  query currentUserAndAssessmentsQuery {
    currentUser {
      ...CurrentUser
      dailyCoaching {
        id
        getsDailyCoaching
      }
      configuration {
        id
        configurationId
        setting
        assessmentName
      }
      canTakePersonality: canTakeAssessment(assessment: ${ASSESSMENT.PERSONALITY})
      canTakeInstinctiveDrives: canTakeAssessment(assessment: ${ASSESSMENT.INSTINCTIVEDRIVES})
      canTakeEnneagram: canTakeAssessment(assessment: ${ASSESSMENT.ENNEAGRAM})
      canTakeDisc: canTakeAssessment(assessment: ${ASSESSMENT.DISC})
      canTakeStrengthScope: canTakeAssessment(assessment: ${ASSESSMENT.STRENGTHSCOPE})
      canTakeCulturePulse: canTakeAssessment(assessment: ${ASSESSMENT.CULTURE})
      canTakeStrengthsFinder: canTakeAssessment(assessment: ${ASSESSMENT.STRENGTHS})
      canTakeEnergyRhythm: canTakeAssessment(assessment: ${ASSESSMENT.ENERGYRHYTHM})
      canTakeDrivingForces: canTakeAssessment(assessment: ${ASSESSMENT.DRIVINGFORCES})
      canTakeMotivatingValues: canTakeAssessment(assessment: ${ASSESSMENT.MOTIVATINGVALUES})
      canTakeVia: canTakeAssessment(assessment: ${ASSESSMENT.VIA})
    }
  }
  ${currentUserFragment}
`, {
  options: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
});

export const strengthsFinderOptionsQuery = gql`
  query strengthsFinderOptions {
    assessment(name: STRENGTHS) {
      options {
        name
        nameKey
        description
      }
    }
  }
`;

export const viaOptionsQuery = gql`
  query viaOptions {
    assessment(name: VIA) {
      options {
        name
        nameKey
        description
      }
    }
  }
`;

const SET_SIXTEEN_TYPES_ASSESSMENT_MUTATION = gql`
 mutation submitPersonality($input: PersonalityAssessmentInput!) {
    setPersonalityAssessment(input: $input) {
      score {
        ...PersonalityScoreFragment
      }
    }
  }
  ${personalityScoreFragment}
`;

const SET_DISC_ASSESSMENT_MUTATION = gql`
  mutation submitDisc($input: DiscAssessmentInput!) {
    setDiscAssessment(input: $input) {
      user {
        id
        scores {
          disc {
            ...DiscScoreFragment
          }
        }
      }
    }
  }
  ${discScoreFragment}
`;

const SET_ENNEAGRAM_ASSESSMENT_MUTATION = gql`
  mutation submitEnneagram($input: EnneagramAssessmentInput!) {
    setEnneagramAssessment(input: $input) {
      user {
        id
        avatar
        fullName
        scores {
          enneagram {
            graphic
            title {
              id
              name
              secondaryName
              characteristics
            }
            triad {
              id
              name
            }
            wing {
              id
              name
              secondaryName
            }
          }
        }
      }
    }
  }
`;

const SET_ENERGY_RHYTHM_ASSESSMENT_MUTATION = gql`
  mutation answerEnergyRhythm($answers: [EnergyRhythmAnswerInput!]) {
    answerNewAssessment(energyRhythm: { answers: $answers, submit: true }) {
      user {
        id
        scores {
          energyRhythm {
            title {
              id
              name
              secondaryName
              description
            }
            traits {
              id
              name
              value
              secondaryName
              description
            }
          }
        }
      }
    }
  }
`;

const SET_INSTINCTIVE_DRIVES_ASSESSMENT_MUTATION = gql`
  mutation answerInstinctiveDrives($answers: [InstinctiveDrivesAnswerInput!]) {
    answerNewAssessment(instinctiveDrives: { answers: $answers, submit: true }) {
      user {
        id
        scores {
          instinctiveDrives {
            isSearching
            title {
              id
              name
              description
            }
            traits {
              id
              name
              nameKey
              secondaryName
              description
              value
            }
          }
        }
      }
    }
  }
`;

const SET_VIA_ASSESSMENT_MUTATION = gql`
 mutation submitVia($input: ViaAssessmentInput!) {
    setViaAssessment(input: $input) {
      score {
        title {
          id
          name
          secondaryName
          description
        }
        traits {
          id
          name
          nameKey
          secondaryName
          secondaryNameKey
          description
          order
        }
      }
    }
  }
`;

const SET_MOTIVATING_VALUES_ASSESSMENT_MUTATION = gql`
  mutation submitMotivatingValues($input: MotivatingValuesAssessmentInput!) {
    setMotivatingValuesAssessment(input: $input) {
      score {
        traits {
          id
          name
          nameKey
          value
          description
          order
        }
      }
    }
  }
`;

const SET_CULTURE_PULSE_ASSESSMENT_MUTATION = gql`
  mutation submitCulturePulse($input: CulturePulseAssessmentInput!) {
    setCulturePulseAssessment(input: $input) {
      user {
        id
        scores {
          culture {
            traits {
              id
              name
              nameKey
              description
              value
              order
            }
          }
        }
      }
    }
  }
`;

const SET_STRENGTHSCOPE_ASSESSMENT_MUTATION = gql`
  mutation submitStrengthscope($answers: [StrengthscopeAnswerInput!]!) {
    answerNewAssessment(strengthscope: { answers: $answers, submit: true }) {
      user {
        id
        scores {
          strengthscope {
            graphic
            isPremium
            title {
              id
              name
              secondaryName
              description
            }
            traits {
              id
              name
              secondaryName
              iconUrl
              descriptions {
                label
                message
                isPremium
              }
            }
          }
        }
      }
    }
  }
`;

export const ASSESSMENT_PAGE_TYPE = {
  INSTRUCTIONS: "instructions",
  INTRODUCTION: "introduction",
  QUESTION: "question",
  SUBMIT: "submit",
};

/**
 * Map query parameter to ASSESSMENT
 */
export const ASSESSMENT_KEY = {
  "16types": ASSESSMENT.PERSONALITY,
  disc: ASSESSMENT.DISC,
  enneagram: ASSESSMENT.ENNEAGRAM,
  "energy-rhythm": ASSESSMENT.ENERGYRHYTHM,
  "instinctive-drives": ASSESSMENT.INSTINCTIVEDRIVES,
  via: ASSESSMENT.VIA,
  "motivating-values": ASSESSMENT.MOTIVATINGVALUES,
  "culture-pulse": ASSESSMENT.CULTURE,
  strengthscope: ASSESSMENT.STRENGTHSCOPE,
};

export const ASSESSMENT_LINKS = {
  [ASSESSMENT.DISC]: "/take/disc",
  [ASSESSMENT.PERSONALITY]: "/take/16types",
  [ASSESSMENT.CULTURE]: "/take/culture-pulse",
  [ASSESSMENT.VIA]: "/take/via",
  [ASSESSMENT.ENNEAGRAM]: "/take/enneagram",
  [ASSESSMENT.MOTIVATINGVALUES]: "/take/motivating-values",
  [ASSESSMENT.INSTINCTIVEDRIVES]: "/take/instinctive-drives",
  [ASSESSMENT.ENERGYRHYTHM]: "/take/energy-rhythm",
  [ASSESSMENT.STRENGTHSCOPE]: "/take/strengthscope",
  [ASSESSMENT.STRENGTHS]: STRENGTHSFINDER_URL,
  [ASSESSMENT.INSIGHTS_DISCOVERY]: INSIGHTS_DISCOVERY_URL,
};

export const ASSESSMENT_GQL = {
  // Sixteen Types
  [ASSESSMENT.PERSONALITY]: {
    query: getSixteenTypesQuestionsQuery,
    mutation: SET_SIXTEEN_TYPES_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.DISC]: {
    query: getDiscQuestionsQuery,
    mutation: SET_DISC_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.ENNEAGRAM]: {
    query: getEnneagramQuestionsQuery,
    mutation: SET_ENNEAGRAM_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.ENERGYRHYTHM]: {
    query: getEnergyRhythmQuestionsQuery,
    mutation: SET_ENERGY_RHYTHM_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.INSTINCTIVEDRIVES]: {
    query: getInstinctiveDrivesQuestionsQuery,
    mutation: SET_INSTINCTIVE_DRIVES_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.VIA]: {
    query: getViaQuestionsQuery,
    mutation: SET_VIA_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.MOTIVATINGVALUES]: {
    query: getMotivatingValuesQuestionsQuery,
    mutation: SET_MOTIVATING_VALUES_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.CULTURE]: {
    query: getCulturePulseQuestionsQuery,
    mutation: SET_CULTURE_PULSE_ASSESSMENT_MUTATION,
  },
  [ASSESSMENT.STRENGTHSCOPE]: {
    query: strengthscopeAssessmentQuestionsQuery,
    mutation: SET_STRENGTHSCOPE_ASSESSMENT_MUTATION,
  },
};

export const ASSESSMENT_CONFIG = {
  // Sixteen Types
  [ASSESSMENT.PERSONALITY]: {
    introTitle: "16types.intro.title",
    length: "16types.intro.length",
    time: "16types.intro.time",
    title: "16types.title",
    questionsPerPage: 5,
    breaks: {
      3: {
        icon: "thumbs-up",
        content: `#16types.break.1.heading
  16types.break.1.paragraph-1

  16types.break.1.paragraph-2

  16types.break.1.paragraph-3
        `,
      },
      7: {
        icon: "clock",
        content: `#16types.break.2.heading
  16types.break.2.paragraph-1
      
  16types.break.2.paragraph-2
        `,
      },
      12: {
        icon: "list-check",
        content: `#16types.break.3.heading
  16types.break.3.paragraph-1

  16types.break.3.paragraph-2
        `,
      },
    },
  },
  [ASSESSMENT.DISC]: {
    introTitle: "disc.intro.title",
    length: "disc.intro.length",
    time: "disc.intro.time",
    title: "disc.title",
    questionsPerPage: 2,
    breaks: {
      3: {
        icon: "thumbs-up",
        content: `#disc.break.1.heading
  disc.break.1.paragraph-1

  disc.break.1.paragraph-2

  disc.break.1.paragraph-3
        `,
      },
      7: {
        icon: "clock",
        content: `#disc.break.2.heading
  disc.break.2.paragraph-1
      
  disc.break.2.paragraph-2
        `,
      },
      12: {
        icon: "list-check",
        content: `#disc.break.3.heading
  disc.break.3.paragraph-1

  disc.break.3.paragraph-2
        `,
      },
    },
  },
  [ASSESSMENT.ENNEAGRAM]: {
    introTitle: "enneagram.intro.title",
    length: "enneagram.intro.length",
    time: "enneagram.intro.time",
    title: "enneagram.title",
    questionsPerPage: 5,
    breaks: {
      5: {
        icon: "thumbs-up",
        content: `#enneagram.break.1.heading
  enneagram.break.1.paragraph-1

  enneagram.break.1.paragraph-2

  enneagram.break.1.paragraph-3
        `,
      },
      10: {
        icon: "clock",
        content: `#enneagram.break.1.heading
  enneagram.break.1.paragraph-1

  enneagram.break.1.paragraph-2
        `,
      },
      16: {
        icon: "list-check",
        content: `#enneagram.break.1.heading
  enneagram.break.1.paragraph-1

  enneagram.break.1.paragraph-2
        `,
      },
    },
  },
  [ASSESSMENT.ENERGYRHYTHM]: {
    introTitle: "energy-rhythm.intro.title",
    length: "energy-rhythm.intro.length",
    time: "energy-rhythm.intro.time",
    title: "energy-rhythm.title",
    questionsPerPage: 1,
  },
  [ASSESSMENT.INSTINCTIVEDRIVES]: {
    introTitle: "id.intro.title",
    length: "id.intro.length",
    time: "id.intro.time",
    title: "id.title",
    questionsPerPage: 1,
  },
  [ASSESSMENT.VIA]: {
    introTitle: "via.intro.title",
    length: "via.intro.length",
    time: "via.intro.time",
    title: "via.title",
    questionsPerPage: 6,
  },
  [ASSESSMENT.MOTIVATINGVALUES]: {
    introTitle: "motivating-values.intro.title",
    length: "motivating-values.intro.length",
    time: "motivating-values.intro.time",
    title: "motivating-values.title",
    questionsPerPage: 1,
    breaks: {
      4: {
        icon: "thumbs-up",
        content: `#motivating-values.break.1.heading
  motivating-values.break.1.paragraph-1

  motivating-values.break.1.paragraph-2

  motivating-values.break.1.paragraph-3
        `,
      },
      7: {
        icon: "user",
        content: `#motivating-values.break.1.heading
  motivating-values.break.1.paragraph-1

  motivating-values.break.1.paragraph-2
        `,
      },
    },
  },
  [ASSESSMENT.CULTURE]: {
    introTitle: "culture-pulse.intro.title",
    length: "culture-pulse.intro.length",
    time: "culture-pulse.intro.time",
    title: "culture-pulse.title",
    questionsPerPage: 4,
    breaks: {
      3: {
        icon: "thumbs-up",
        content: `#culture-pulse.break.1.heading
  culture-pulse.break.1.paragraph-1

  culture-pulse.break.1.paragraph-2

  culture-pulse.break.1.paragraph-3
        `,
      },
      5: {
        icon: "chart-pie",
        content: `#culture-pulse.break.1.heading
  culture-pulse.break.1.paragraph-1

  culture-pulse.break.1.paragraph-2
        `,
      },
    },
  },
  [ASSESSMENT.STRENGTHSCOPE]: {
    introTitle: "strengthscope.intro.title",
    introDescription: "strengthscope.intro.description",
    length: "strengthscope.intro.length",
    time: "strengthscope.intro.time",
    title: "strengthscope.title",
  }
};

export const ASSESSMENT_QUESTION = {
  // Sixteen Types
  [ASSESSMENT.PERSONALITY]: {
    answers: SixteenTypesAnswers,
    Example: SixteenTypesExampleQuestion,
    Question: SixteenTypesQuestion,
    validate: response => response.length !== 70 ||
      response.some(r => r.response === "" || r.response == null),
  },
  [ASSESSMENT.DISC]: {
    answers: DiscAnswers,
    Example: DiscExampleQuestion,
    Question: DiscQuestion,
    validate: response => response.length !== 48 // TOTAL_DISC_QUESTIONS / Answers*
      || response.some(r => r.description === "" || r.description == null),
  },
  [ASSESSMENT.ENNEAGRAM]: {
    answers: EnneagramAnswers,
    Example: EnneagramExampleQuestion,
    Question: EnneagramQuestion,
    validate: response => response.length !== 108 // TOTAL_ENNEAGRAM_QUESTIONS
      || response.some(r => r.response === "" || r.response == null),
  },
  [ASSESSMENT.ENERGYRHYTHM]: {
    Example: EnergyRhythmExampleQuestion,
    Question: EnergyRhythmQuestion,
    validate: response => response.length !== 7 // TOTAL_ENERGYRHYTHM_QUESTIONS
      || response.some(r => r.answer === "" || r.answer == null),
  },
  [ASSESSMENT.INSTINCTIVEDRIVES]: {
    answers: InstinctiveDriveAnswers,
    Example: InstinctiveDrivesExampleQuestion,
    Question: InstinctiveDrivesQuestion,
    validate: response => response.some(r => (
      Boolean(r.answer1 === "" || r.answer1 == null)
      || Boolean(r.answer2 === "" || r.answer2 == null)
      || Boolean(r.answer3 === "" || r.answer3 == null)
      || Boolean(r.answer4 === "" || r.answer4 == null)
    )),
  },
  [ASSESSMENT.VIA]: {
    answers: ViaAnswers,
    Example: ViaExampleQuestion,
    Question: ViaQuestion,
    validate: response => response.length !== 96 // TOTAL_VIA_QUESTIONS
      || response.some(r => r === "" || r == null),
  },
  [ASSESSMENT.MOTIVATINGVALUES]: {
    answers: MotivatingValuesAnswers,
    Example: MotivatingValuesExampleQuestion,
    Question: MotivatingValuesQuestion,
    validate: response => response.length !== 9, // TOTAL_MOTIVATING_VALUES_QUESTIONS
  },
  [ASSESSMENT.CULTURE]: {
    answers: CulturePulseAnswers,
    Example: CulturePulseExampleQuestion,
    Question: CulturePulseQuestion,
    validate: response => response.length !== 24 // TOTAL_CULTURE_QUESTIONS
      || response.some(r => r.score === "" || r.score == null),
  },
  [ASSESSMENT.STRENGTHSCOPE]: {
    Question: StrengthscopeQuestion,
    validate: response => response.some(r => r.answerId === "" || r.answerId == null),
  },
}
