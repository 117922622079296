import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "grommet";
import { getThemeColor } from "@/cloverleaf-ui/theme";
import { Tip, TipContent } from "../Tip";

/**
 * Component wrapper for <FontAwesomeIcon />
 * https://fontawesome.com/v5/docs/web/use-with/react
 *
 * @param {string} icon - FontAwesomeIcon name
 * @param {object} tip - All valid Tip props
 */
const Icon = React.forwardRef(({
  color = "grey700",
  disabled = false,
  icon: faIcon,
  plain = false,
  skeleton,
  tip = undefined,
  ...props
}, ref) => {
  if (skeleton) {
    return <Avatar skeleton={skeleton} size="xsmall" />;
  }

  const iconColor = !plain ? getThemeColor(color) : undefined;

  /**
   * Because FontAwesomeIcon does not use forwardRef itself
   * https://github.com/FortAwesome/react-fontawesome/issues/199
   */
  const FaIcon = React.forwardRef(function FaIcon(p, fRef) {
    return React.createElement(FontAwesomeIcon, {
      ref: fRef,
      color: iconColor,
      icon: faIcon,
      opacity: disabled ? 0.7 : 1,
      ...p,
    });
  });

  if (tip && tip.message) {
    return (
      <Tip ref={ref} {...tip}>
        <FaIcon role="img" {...props} />
      </Tip>
    );
  }

  return <FaIcon role="img" ref={ref} {...props} />;
});

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  plain: PropTypes.bool,
  tip: PropTypes.shape({
    ...TipContent.propTypes,
  }),
};

Icon.displayName = "Icon";

export { Icon };
