import React from "react";
import {
  Box,
  Button,
  Nav,
  ResponsiveContext
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { isMobile } from "@/cloverleaf-ui/utils";
import { ConversationCardsButton } from "../../Navbar/components/ConversationCardsButton";
import { HelpMenu } from "../../Navbar/components/HelpMenu";
import { MyOrganizationMenu } from "../../Navbar/components/MyOrganizationsMenu";
import { UserMenu } from "../../Navbar/components/UserMenu/UserMenu";
import { SidebarContext } from "./Sidebar.context";
import { UniversalSearch } from "./UniversalSearch";
import { Log } from "@/lib/analytics/Log";
import { useLocale, useShowDirectory } from "@/lib/hooks";

const Header = ({ showSidebar = false }) => {
  const screenSize = React.useContext(ResponsiveContext);
  const { lang } = useLocale();
  const { toggleSidebar } = React.useContext(SidebarContext);
  const showDirectory = useShowDirectory();

  return (
    <Log section="header">
      <Box
        border="bottom"
        gridArea="header"
        direction="row"
        align="center"
        justify="between"
        pad={{ horizontal: "medium", vertical: "small" }}
        background="white"
        height="56px"
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Nav align="center" direction="row" border={{ side: "between" }}>
          {isMobile(screenSize) && showSidebar && (
            <Button
              onClick={toggleSidebar}
              label={(
                <Box pad={{ vertical: "small", right: "small", left: "xsmall" }}>
                  <Icon icon="bars" color="grey700" />
                </Box>
              )}
              plain
            />
          )}
          <Log component="MyOrganizationMenu">
            <MyOrganizationMenu />
          </Log>
        </Nav>
        <Nav align="center" direction="row" gap="medium">
          {showDirectory && <UniversalSearch />}
          {showDirectory && <Box border={{ side: "right", color: "grey400" }} height="20px" />}
          {lang === "en" && <ConversationCardsButton />}
          {lang === "en" && (
            <Box border={{ side: "right", color: "grey400" }} height="20px" />
          )}
          <HelpMenu />
          <UserMenu />
        </Nav>
      </Box>
    </Log>
  );
};

export { Header };
