// Enneagram
export const WING = "Flügel";
export const TRIAD = "Triade";
export const GROWTH = "Wachstum";
export const STRESS = "Stress";
// Culture Pulse
export const MEANS = "Mittel zum Zweck";
export const GOAL = "Ziel";
export const INTERNALLY_DRIVEN = "intern gesteuert";
export const EXTERNALLY_DRIVEN = "extern gesteuert";
export const LOOSE = "locker";
export const STRICT = "strikt";
export const COMMUNITY = "Gemeinschaft";
export const PROFESSIONAL = "professionell";
export const APPROACHABLE = "zugänglich";
export const GUARDED = "behütet";
export const SUPPORT = "Unterstützung";
export const PERFORMANCE = "Leistung";

export const SIXTEEN_TYPES_LABEL = "16 Typen";
export const DISC_LABEL = "DISK";
