import { gql, useQuery } from "@apollo/client";
import { Configuration } from "@/components/utils/constants";

const GET_CAN_MANAGE_ORGS_QUERY = gql`
  query getCanManageOrgsQuery {
    currentUser {
      id
      organization {
        id
        callerCanManage  
        managePartnerOrganizationsFullConfig: configurationSetting(
        configurationId: "${Configuration.MANAGE_PARTNER_ORGANIZATIONS_FULL}"
        ) {
          enabled
        }
        managePartnerOrganizationsConfig: configurationSetting(
          configurationId: "${Configuration.MANAGE_PARTNER_ORGANIZATIONS}",
        ) {
          enabled
        }
      }
    }
  }
`;

export function useCanManageOrgs() {
  const { data } = useQuery(GET_CAN_MANAGE_ORGS_QUERY);

  const currentUserOrganization = data?.currentUser?.organization;

  const isManagePartnerOrganizationsEnabled = currentUserOrganization?.managePartnerOrganizationsConfig?.enabled;

  const isManagePartnerOrganizationsFullEnabled = currentUserOrganization?.managePartnerOrganizationsFullConfig?.enabled;

  const canManageOrgs = (
    (
      currentUserOrganization?.callerCanManage
      && isManagePartnerOrganizationsEnabled
    )
    || (isManagePartnerOrganizationsFullEnabled)
  );

  return { canManageOrgs };
}
