import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormField,
  Select,
  Text,
} from "grommet";
import { FormClose } from "grommet-icons";
import { LoadingIndicator } from "../../atoms/LoadingIndicator";
import { InviteSuggestion } from "./InviteSuggestion";
import { PeopleSuggestion } from "./PeopleSuggestion";
import { Persona } from "./Persona";
import { Avatar } from "@/components/ui";

// eslint-disable-next-line react/prop-types
function Option({ label, selected }) {
  return label?.id ?
    (
      <PeopleSuggestion
        selected={selected}
        user={label}
      />
    )
    : (
      <InviteSuggestion
        label={label?.email}
        selected={selected}
      />
    );
}

const includesString = (haystack, needle) =>
  typeof haystack === "string"
  && typeof needle === "string"
  && haystack?.toLowerCase()?.indexOf(needle?.toLowerCase()) !== -1;

const prefix = "Invite ";

const updateOptionsWithEmailInvite = ({ text, options = [], disabled = false, domain }) => {
  if (disabled) {
    return options;
  }

  // Remove old options with prefix
  const updatedOptions = options.filter(option => !includesString(option?.email, prefix));

  if (text.trim()) {
    const email = text.includes("@") || !domain
      ? `${prefix} ${text}`
      : `${prefix} ${text}@${domain}`;

    updatedOptions.push({ email });
  }

  return updatedOptions;
};

function PeopleDropdownField({
  closeOnChange = false,
  disabled = false,
  dropHeight = "medium",
  emailDomain = undefined,
  inputProps = {},
  label = "Select from list of members",
  loading = false,
  membersOnly = false,
  multiple = true,
  name = "people-select",
  onChange = () => undefined,
  onMore = () => undefined,
  onSearch = () => undefined,
  options = [],
  organization = {},
  placeholder = "Search or add new emails",
  searchPlaceholder = undefined,
  /**
   * @todo can set initial values
   */
  // eslint-disable-next-line unused-imports/no-unused-vars
  value: defaultValue = [],
  personaProps = undefined,
  valueLabelProps = undefined,
  ...props
}) {
  const [selected, setSelected] = React.useState(defaultValue || []);
  const [defaultOptions, setDefaultOptions] = React.useState(options);
  const [dropdownOptions, setDropdownOptions] = React.useState(options);
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const filterOptions = (opts, text = search) => {
    const newOptions = updateOptionsWithEmailInvite({ text, options: opts, disabled: membersOnly, domain: emailDomain });

    const suggestions = newOptions
      .filter(option => (
        includesString(option, text)
        || includesString(option?.fullName, text)
        || includesString(option?.email, text)
      ));

    setDefaultOptions(newOptions);
    setDropdownOptions(suggestions);
  };

  React.useEffect(() => {
    filterOptions(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleOnMore = () => {
    // Rendered full list, fetch additional options
    onMore(search);
  };

  // Adds input to list while searching
  const handleOnSearch = (text) => {
    filterOptions(defaultOptions, text);

    onSearch(text);

    return setSearch(text);
  };

  /**
   * Select onChange event handler, because this is a multiple select,
   * one parameter is a single option and the other is an array of selected options.
   * @param {*} option - option change that triggered the event
   * @param {[]} value - array of already selected options
   * @returns object array of selected values
   */
  const handleOnChange = ({ option, value }) => {
    const newDefaultOptions = defaultOptions;
    let nextValue = !multiple ? [value] : value;

    // Make sure nextValue is an array
    if (!Array.isArray(nextValue)) {
      nextValue = [...selected];

      if (typeof value !== "undefined") {
        // check if a duplicate before we add it back
        if (!selected.find(item => item?.email === value)) {
          nextValue.push({ email: value });
        }
      }
    }
    else { // nextValue is an array, validate entry
      const len = nextValue.length;

      if (typeof nextValue[len - 1] === "undefined") {
        // unpredictable entry, revert to source of truth
        nextValue = [...selected];

        if (option?.email) {
          // remove if a duplicate
          if (selected.find(item => item?.email === option?.email)) {
            nextValue = selected.filter(item => item?.email !== option?.email);
          }
          else {
            nextValue.push(option);
          }
        }
      }
    }

    // Check if we are removing an item
    if (
      selected.length > 0 // we definitely already selected something...
      && nextValue.length < selected.length // the incoming array is smaller...
    ) {
      // trust the incoming array
    }

    // Check prefix if adding an email address to the list
    else if (option?.email?.includes(prefix)) {
      let newValue = { email: search.trim() };

      if (!search.includes("@") && emailDomain) {
        newValue = { email: `${search.split(" ").join("")}@${emailDomain}` };
      }

      // remove placeholder Invite option
      nextValue.pop();
      newDefaultOptions.pop();

      // check if a duplicate before we add it back
      if (selected.find(item => item?.email === newValue?.email)) {
        // Already in selected, return nextValue before pushing the duplicate element
        setSelected(nextValue);

        // Select resets search text, reset dropdown options
        setDropdownOptions(newDefaultOptions);

        return onChange(nextValue);
      }

      // add new email option
      nextValue.push(newValue);
      newDefaultOptions.push(newValue);
    }

    // Filter undefined from array, just in case
    const nextSelected = nextValue.filter(v => typeof v !== "undefined" && typeof v.email !== "undefined");

    setSelected(nextSelected);
    setDefaultOptions(newDefaultOptions);

    // Select resets search text, reset dropdown options
    // setSearch('');
    // setDropdownOptions(newDefaultOptions);

    return onChange(nextSelected);
  };

  const handleOnDelete = (item) => {
    const newSelected = selected.filter(i => i !== item);

    setSelected(newSelected);

    return onChange(newSelected);
  };

  const renderSelectedValuesAsPersonas = value => (
    <Persona
      index={value?.id}
      key={value?.email || value?.id || value}
      onDelete={() => handleOnDelete(value)}
      organization={organization}
      value={value}
      {...personaProps}
    />
  );

  const renderDropdownOptions = (option) => {
    if (!option) {
      return [];
    }

    return (
      <Option
        selected={Boolean(selected?.find(item => option.id === item?.id))}
        label={option}
      />
    );
  };

  return (
    <FormField
      // If not an array of disabled items, do not disable
      disabled={Array.isArray(disabled) ? false : disabled}
      htmlFor="people-select-field"
      label={label || loading ? (
        <Box direction="row" justify="between">
          {label && <Text size="medium">{label}</Text>}
          {loading && (
            <LoadingIndicator
              margin="none"
              size="small"
              height="18px"
              width="hair"
              style={{
                padding: "0 12px 0 0",
                margin: "0 18px 0 0",
                width: "18px",
                height: "18px",
              }}
            />
          )}
        </Box>
      ) : undefined}
      name={name}
      {...props}
    >
      <Select
        closeOnChange={closeOnChange}
        disabled={disabled}
        disabledKey={option =>
          Array.isArray(disabled) && disabled?.some(user => user?.id && user.id === option?.id)
        }
        dropAlign={{ top: "bottom" }}
        dropHeight={dropHeight}
        htmlFor="people-select-field"
        icon={!multiple && selected?.length ? <FormClose /> : undefined}
        multiple={multiple}
        name={name}
        onChange={handleOnChange}
        onClose={() => setDropdownOptions(defaultOptions)}
        onMore={handleOnMore}
        onOpen={() => setDropdownOptions(defaultOptions)}
        onSearch={handleOnSearch}
        options={dropdownOptions}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder || placeholder}
        value={selected}
        valueKey={(option) => {
          if (typeof option === "string") {
            return { email: option };
          }

          if (typeof option?.email !== "undefined") {
            return option;
          }

          return { email: "invalid email" };
        }}
        valueLabel={(
          <Box
            align="center"
            direction="row"
            height={{ min: "36px" }}
            pad={{ horizontal: "xsmall" }}
            wrap
            {...valueLabelProps}
          >
            {selected?.length ? (
              multiple ? selected.map(item => renderSelectedValuesAsPersonas(item)) : (
                <Box
                  align="center"
                  direction="row"
                  gap="small"
                >
                  <Avatar user={selected[0]} expandable={false} />
                  <Text textAlign="center" truncate>{selected[0].fullName || selected[0].email}</Text>
                </Box>
              )
            ) : placeholder}
          </Box>
        )}
        {...inputProps}
      >
        {renderDropdownOptions}
      </Select>
    </FormField>
  );
}

PeopleDropdownField.propTypes = {
  closeOnChange: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
  dropHeight: PropTypes.string,
  emailDomain: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  loading: PropTypes.bool,
  membersOnly: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onMore: PropTypes.func,
  onSearch: PropTypes.func,
  organization: PropTypes.shape(),
  options: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  personaProps: PropTypes.shape(),
  searchPlaceholder: PropTypes.string,
  value: PropTypes.array,
  valueLabelProps: PropTypes.shape(),
};

export { PeopleDropdownField };
