import React, { useState, useContext } from "react";
import { useRouter } from "next/router";
import { useQuery, gql } from "@apollo/client";
import {
  Box,
  Text,
  ThemeContext
} from "grommet";
import { Avatar, Icon } from "@/cloverleaf-ui/components";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import { navbarButtonTheme } from "@/cloverleaf-ui/theme";
import { AddAccountMenuItem } from "./AddAccountMenuItem";
import { useLanguageChooserMenuItems } from "./useLanguageChooserMenuItems";
import { useSwitchAccountMenuItems } from "./useSwitchAccountMenuItems";
import { EmployeeToolsContext } from "@/components/composite/EmployeeToolbox";
import { languages } from "@/components/composite/Internationalization/i18n.utils";
import { Menu } from "@/components/v2/Menu";
import { EventNames } from "@/lib/analytics/constants";
import { useLocale } from "@/lib/hooks";
import { useDebugAnalytics } from "@/lib/hooks/useDebugAnalytics";
import { useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS } from "@/lib/hooks/useIsCloverleafEmployee";

const GET_CURRENT_USER_AVATAR_AND_PERMISSIONS_QUERY = gql`
  query currentUserAvatarAndPermissions {
    currentUser {
      id
      avatar
      email
      fullName
      hasAdminPermission
      isCloverleafAdmin
      organization {
        id
      }
      profile {
        id
      }
    }
  }
`;

function UserMenu() {
  const { t } = useLocale();
  const router = useRouter();
  const { toggleEmployeeToolbox } = useContext(EmployeeToolsContext);

  const [mainMenu, setMainMenu] = useState(false);
  const [switchAccountSubmenu, setSwitchAccountSubmenu] = useState(false);
  const [languageChooserSubmenu, setLanguageChooserSubmenu] = useState(false);
  const [groupContentSubmenu, setGroupContentSubmenu] = useState(false);

  const accountItems = useSwitchAccountMenuItems();
  const languageItems = useLanguageChooserMenuItems();
  const addAccountItem = AddAccountMenuItem();
  const { isDebugMode, toggleDebugMode } = useDebugAnalytics();

  const { name: currentLanguage } = languages.find(lang => lang.language === router.locale);

  const { data } = useQuery(GET_CURRENT_USER_AVATAR_AND_PERMISSIONS_QUERY);

  /** This hook is used her in toolBox as it ignores demo mode */
  const isCloverleafEmployee = useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS();

  if (!data) {
    return null;
  }

  const { hasAdminPermission, organization, isCloverleafAdmin } = data.currentUser || {};
  const organizationId = organization?.id;

  /**
   * Reseting sub menu visibilty when sub menu's are closed to make room for main menu;
   * or when sub menu's are closed externally (esc, outside click) -- assumes clicking on
   * the navbar icon should always re-open at the main menu and not a sub menu.
   */
  const resetSubMenuVisibility = () => {
    setSwitchAccountSubmenu(false);
    setLanguageChooserSubmenu(false);
    setGroupContentSubmenu(false);
  };

  const dynamicMenuGroup = [
    {
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon="gear"
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: t("account-settings"),
      href: "/account/settings",
    },
    {
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon={["fas", "chevron-right"]}
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: (
        <Box align="center" direction="row" flex="grow" gap="medium">
          <Icon
            icon={["far", "globe"]}
            color="grey700"
            size="1x"
          />
          <Text>{`${t("nav.language")}: ${currentLanguage}`}</Text>
        </Box>
      ),
      reverse: true,
      onClick: () => setLanguageChooserSubmenu(true),
    },
  ];

  if (hasAdminPermission) {
    dynamicMenuGroup.push({
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon={["fas", "chart-pie"]}
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: t("nav.admin-settings"),
      href: `/organization/${organizationId}/settings/configuration/general`,
    });
  }

  if (isCloverleafAdmin) {
    dynamicMenuGroup.push({
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon={["far", "user-lock"]}
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: "Super Admin",
      href: "/admin",
    }, {
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon={["far", isDebugMode ? "bug" : "bug-slash"]}
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: "Debug Analytics",
      onClick: () => toggleDebugMode(),
    },{
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon={["fas", "chevron-right"]}
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: (
        <Box align="center" direction="row" flex="grow" gap="medium">
          <Icon
            icon={["far", "random"]}
            color="grey700"
            size="1x"
          />
          <Text>Group Content Management</Text>
        </Box>
      ),
      reverse: true,
      onClick: () => setGroupContentSubmenu(true),
    });
  }

  if (isCloverleafEmployee) {
    dynamicMenuGroup.push({
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon={["fas", "clover"]}
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: "Employee Toolbox",
      onClick: () => {
        toggleEmployeeToolbox();
      },
    });
  }

  const accountMenuItem = accountItems.length === 1 ? {
    ...addAccountItem,
  } : {
    gap: "small",
    icon: (
      <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
        <Icon
          fixedWidth
          icon={["fas", "chevron-right"]}
          color="grey700"
          size="1x"
        />
      </Box>
    ),
    label: (
      <Box align="center" direction="row" flex="grow" gap="medium">
        <Icon
          icon={["far", "random"]}
          color="grey700"
          size="1x"
        />
        <Text>{t("switch-account-menu.title")}</Text>
      </Box>
    ),
    reverse: true,
    onClick: () => setSwitchAccountSubmenu(true),
  };

  let menuItems = [[{
    autoFocus: true,
    gap: "small",
    icon: <Avatar size="xsmall" user={data?.currentUser}/>,
    label: data?.currentUser?.email,
    href: `/p/${data?.currentUser?.profile?.id}`,
  }],
  [...dynamicMenuGroup],
  [
    accountMenuItem,
    {
      gap: "small",
      icon: (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon="arrow-right-from-bracket"
            color="grey700"
            size="1x"
          />
        </Box>
      ),
      label: t("sign-out"),
      onClick: () => router.push("/signout"),
    },
  ]];

  const backMenuItem = {
    autoFocus: true,
    gap: "small",
    onClick: () => {
      /**
       * When clicking back, sub menu's are closed and main menu is opened
       */
      setMainMenu(true);
      resetSubMenuVisibility();
    },
    icon: (
      <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
        <Icon
          color="grey700"
          icon="arrow-left"
          size="1x"
        />
      </Box>
    ),
    label: t("back"),
  };

  return (
    <ThemeContext.Extend value={navbarButtonTheme}>
      {switchAccountSubmenu && (
        <Menu
          open
          alignSelf="center"
          dropAlign={DROP_POSITION.bottom}
          items={[
            [backMenuItem],
            [...accountItems],
            [addAccountItem],
          ]}
          logEventProps={{
            elementName: "switch-account-menu",
            eventName: EventNames.UserMenuClicked,
          }}
          messages={{
            openMenu: t("nav.user-settings-menu-open"),
            closeMenu: t("nav.user-settings-menu-close")
          }}
          plain
        >
          <Avatar
            // for focus/hover effect
            onClick={() => resetSubMenuVisibility()}
            onKeyDown={() => resetSubMenuVisibility()}
            size="xsmall"
            user={data?.currentUser}
          />
        </Menu>
      )}
      {languageChooserSubmenu && (
        <Menu
          open
          alignSelf="center"
          dropAlign={DROP_POSITION.bottom}
          items={[
            [backMenuItem],
            languageItems
          ]}
          logEventProps={{
            elementName: "language-chooser-menu",
            eventName: EventNames.UserMenuClicked,
          }}
          messages={{
            openMenu: t("nav.user-settings-menu-open"),
            closeMenu: t("nav.user-settings-menu-close")
          }}
          plain
        >
          <Avatar
            // For focus/hover effect
            onClick={() => resetSubMenuVisibility()}
            onKeyDown={() => resetSubMenuVisibility()}
            size="xsmall"
            user={data?.currentUser}
          />
        </Menu>
      )}
      {groupContentSubmenu && (
        <Menu
          open
          alignSelf="center"
          dropAlign={DROP_POSITION.bottom}
          items={[
            backMenuItem,
            {
              onClick: () => router.push("/admin-content-manager/group/trigger"),
              label: "Group Tips manager",
            },
            {
              onClick: () => router.push("/meetings/prep"),
              label: "Meeting Prep",
            },
            {
              onClick: () => router.push("/coaching-view/insights"),
              label: "Coaching View Insights",
            }
          ]}
          plain
        >
          <Avatar
            // For focus/hover effect
            onClick={() => resetSubMenuVisibility()}
            onKeyDown={() => resetSubMenuVisibility()}
            size="xsmall"
            user={data?.currentUser}
          />
        </Menu>
      )}
      {!languageChooserSubmenu && !switchAccountSubmenu && !groupContentSubmenu && (
        <Menu
          alignSelf="center"
          dropAlign={DROP_POSITION.bottom}
          items={menuItems}
          logEventProps={{
            elementName: "user-menu",
            eventName: EventNames.UserMenuClicked,
          }}
          messages={{
            openMenu: t("nav.user-settings-menu-open"),
            closeMenu: t("nav.user-settings-menu-close")
          }}
          open={mainMenu}
          plain
        >
          <Avatar
            // For focus/hover effect
            // Reset main menu state onClick for re-opening
            onClick={() => setMainMenu(true)}
            size="xsmall"
            user={data?.currentUser}
          />
        </Menu>
      )}
    </ThemeContext.Extend>
  );
}

export { UserMenu };
