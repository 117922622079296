import React from "react";
import { useRouter } from "next/router";
import {
  gql,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import { setCookie } from "nookies";
import {
  Box,
  Grommet,
  Select,
  Text,
} from "grommet";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { track } from "../../../lib";
import { EVENT } from "../../utils/constants";
import { languages } from "./i18n.utils";
import { publicRuntimeConfig } from "@/lib/config";
import { useAuth } from "@/lib/hooks";

const updateUserLocale = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      locale
    }
  }
`;

function LanguageChooser() {
  const router = useRouter();

  const authenticated = useAuth();

  const apolloClient = useApolloClient();

  const [updateUser] = useMutation(updateUserLocale);

  const handleOnChange = ({ option }) => {
    track(EVENT.LanguageChange.name, { language: option.language });

    if (authenticated) {
      return updateUser({
        variables: {
          input: {
            locale: option.language,
          },
        },
      })
        .then(() => {
          setCookie(null, "NEXT_LOCALE", option.language, {
            domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN,
            maxAge: 30 * 24 * 60 * 60 * 1000, // default to 30 days, in ms
            path: "/",
          });

          const { pathname, asPath, query } = router;

          return router.push({
            pathname,
            query,
          }, `/${option.language}${asPath}`, {
            locale: option.language,
          }).then(() => apolloClient.cache.reset());
        });
    }

    setCookie(null, "NEXT_LOCALE", option.language, {
      domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN,
      maxAge: 30 * 24 * 60 * 60 * 1000, // default to 30 days, in ms
      path: "/",
    });

    const { pathname, asPath, query } = router;

    return router.push({
      pathname,
      query,
    }, `/${option.language}${asPath}`, {
      locale: option.language,
    }).then(() => apolloClient.cache.reset());
  };

  return (
    <Grommet theme={themeStyle}>
      <Box
        align="center"
        justify="center"
        direction="row"
        gap="small"
        wrap
      >
        <Select
          id="language"
          a11yTitle="Select Language"
          name="language"
          options={languages}
          labelKey="name"
          onChange={handleOnChange}
          value={languages.find(lang => lang.language === router.locale)}
          valueLabel={value => (
            <Box pad="small">
              <Text color="white">{value?.name}</Text>
            </Box>
          )}
        />
      </Box>
    </Grommet>
  );
}

export default LanguageChooser;
