import React from "react";
import { gql, useQuery } from "@apollo/client";
import throttle from "lodash/fp/throttle";
import { omitUndefined } from "@/components/utils";

const SORT_COLUMNS = {
  ID: "ID",
  FIRST: "FIRST",
  LAST: "LAST",
  EMAIL: "EMAIL",
};

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

export const RELAY_USER_SEARCH_QUERY = gql`
  query relayUserSearch(
    $userId: ID
    $search: UserSearchInput
    $sort: [UserSort!]
    $first: Int
    $after: String
    $last: Int
    $before: String
    $organizationId: ID
  ) {
    relayUserSearch(
      userId: $userId, 
      search: $search,
      sort: $sort,
      first: $first,
      after: $after,
      last: $last,
      before: $before,
    ) {
      type
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          ... on UserNode {
            user {
              id
              fullName
              email
              avatar
              role
              isOnTeamWith
              canBeViewedByCurrentUser
              profile {
                id
                relativePublicUrl
              }
              created
              consentedAt
              isMember(organizationId:$organizationId)
              isMemberInHierarchy(organizationId:$organizationId)
              isAdmin(organizationId:$organizationId)
              isCandidate
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CROSS_ORG_RELAY_USER_SEARCH_QUERY = gql`
  query crossOrgRelayUserSearch(
    $userId: ID
    $search: UserSearchInput
    $sort: [UserSort!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    crossOrgRelayUserSearch(
      userId: $userId,
      search: $search,
      sort: $sort,
      first: $first,
      after: $after,
      last: $last,
      before: $before,
    ) {
      type
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          ... on UserNode {
            user {
              id
              fullName
              email
              avatar
              role
              isOnTeamWith
              canBeViewedByCurrentUser
              profile {
                id
                relativePublicUrl
              }
              created
              consentedAt
              isCandidate
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Enhanced hook for user search with standardized data extraction and improved state management
 * @param {object} input - Configuration options
 * @param {string} input.userId - ID!
 * @param {object} input.search - search query parameters
 * @param {string} input.search.text - search by name or email
 * @param {string} input.search.topParentId - ID!
 * @param {object} input.search.userSearchFilters - additional search filters
 * @param {array} input.sort - sort query parameters
 * @param {number} input.first - Limit first pagination
 * @param {string} input.after
 * @param {number} input.last
 * @param {string} input.before
 * @param {boolean} input.crossOrgSearch
 * @param {boolean} input.performInitialSearch - Whether to perform an empty search on mount
 */
const useRelayUserSearch = ({
  userId,
  search: _search,
  sort: _sort,
  first = 15,
  after,
  last,
  before,
  organizationId,
  crossOrgSearch = false,
  performInitialSearch = true,
  ...rest
}) => {
  // Standardize search state with text field
  const [searchText, setSearchText] = React.useState(_search?.text || "");

  // Track if initial search has been performed
  const initialSearchPerformedRef = React.useRef(false);

  const [search, setSearch] = React.useState({
    ..._search,
    text: searchText,
    userSearchFilters: {
      onlyCanView: true, // set to true by default
      ..._search?.userSearchFilters || {},
    },
  });

  React.useEffect(() => {
    const updatedSearch = {
      ..._search,
      text: searchText,
      userSearchFilters: {
        onlyCanView: true, // set to true by default
        ..._search?.userSearchFilters || {},
      },
    };

    const searchChanged = JSON.stringify(search) !== JSON.stringify(updatedSearch);
    if (searchChanged) {
      setSearch(updatedSearch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_search, searchText]);

  const initialSort = [{
    column: _sort?.column || SORT_COLUMNS.ID,
    order: _sort?.order || SORT_ORDER.ASC,
  }];

  const [sort, setSort] = React.useState(initialSort);

  const variables = omitUndefined({
    userId,
    search,
    sort,
    first,
    after,
    last,
    before,
    organizationId,
  });

  const {
    data,
    error,
    loading,
    fetchMore: originalFetchMore,
    refetch,
  } = useQuery(crossOrgSearch ? CROSS_ORG_RELAY_USER_SEARCH_QUERY : RELAY_USER_SEARCH_QUERY, {
    variables,
    skip: !performInitialSearch && !initialSearchPerformedRef.current,
    ...rest
  });

  // Perform initial search if configured
  React.useEffect(() => {
    if (performInitialSearch && !initialSearchPerformedRef.current) {
      initialSearchPerformedRef.current = true;
    }
  }, [performInitialSearch]);

  // Standardize data extraction
  const searchData = crossOrgSearch ? data?.crossOrgRelayUserSearch : data?.relayUserSearch;
  const users = searchData?.edges?.map(edge => edge?.node?.user) || [];
  const pageInfo = searchData?.pageInfo || {};

  const debouncedRefetch = React.useCallback((v) => {
    return throttle({ wait: 1000, leading: true })(() => refetch(v));
  }, [refetch]);

  /**
   * Enhanced fetchMore that checks for hasNextPage
   * @param {object} args - Additional variables to pass to fetchMore
   * @returns {Promise|undefined} - Returns the fetchMore promise or undefined if no more pages
   */
  const handleFetchMore = (args = {}) => {
    if (pageInfo?.hasNextPage) {
      return originalFetchMore({
        variables: {
          ...variables,
          ...args,
          after: pageInfo?.endCursor,
        },
      });
    }

    return undefined;
  };

  /**
   * @param {SORT_COLUMNS} column
   * @param {SORT_ORDER} order
   */
  const handleSort = (
    column = SORT_COLUMNS.ID,
    order = SORT_ORDER.ASC,
  ) => {
    const updatedSort = [{
      column,
      order,
    }];

    setSort(updatedSort);

    return refetch({
      ...variables,
      sort: updatedSort
    });
  };

  /**
   * Enhanced search function that updates search text state
   * @param {object} query - Search parameters
   * @param {string} query.text - Search text
   * @param {string} query.topParentId - Organization ID
   * @param {object} query.userSearchFilters - Additional filters
   */
  const handleSearch = async (query) => {
    const { text, topParentId, userSearchFilters } = query;

    const updatedSearch = { ...search };

    if (typeof text !== "undefined") {
      updatedSearch.text = text;
      setSearchText(text);
    }

    if (typeof topParentId !== "undefined") {
      updatedSearch.topParentId = topParentId;
    }

    if (userSearchFilters) {
      updatedSearch.userSearchFilters = {
        ...updatedSearch.userSearchFilters,
        ...userSearchFilters,
      };
    }

    setSearch(updatedSearch);
    initialSearchPerformedRef.current = true;

    return debouncedRefetch({
      search: updatedSearch,
    });
  }

  return {
    data,
    error,
    fetchMore: handleFetchMore,
    loading,
    refetch: debouncedRefetch,
    search: handleSearch,
    sort: handleSort,
    users,
    pageInfo,
    searchText,
  };
}

export { useRelayUserSearch };
