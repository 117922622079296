import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Heading,
  Layer
} from "grommet";
import { FormClose } from "grommet-icons";
import { LoadingIndicator } from "../LoadingIndicator";

function Modal({
  background = undefined,
  children = undefined,
  hideClose = false,
  loading = false,
  onClickOutside = undefined,
  onClose = () => undefined,
  position = "center",
  title = undefined,
  ...props
}) {
  /**
   * Prevent events emitted by modal contents from propagating after the modal is closed.
   *
   * Added in particular to prevent dropdown components from trying to close after the modal closes when clicking outside the modal.
   */
  const closeModal = (event) => {
    event.stopPropagation();

    return onClose(event);
  };

  const handleOnClickOutside = (event) => {
    if (typeof onClickOutside === "undefined") {
      return closeModal(event);
    }

    return onClickOutside?.(event);
  };

  return (
    <Layer
      modal
      background={background}
      onClickOutside={handleOnClickOutside}
      onEsc={closeModal}
      position={position}
      {...props}
    >
      {(title || !hideClose) && (
        <Box align="center" direction="row" justify="between" pad="xsmall">
          {title && (
            <Box align="center" pad="xsmall">
              <Heading level={1} margin="none" size="small">
                {title}
              </Heading>
            </Box>
          )}
          {!hideClose && (
            <Box align="end" pad="xsmall" flex="grow">
              <Button icon={<FormClose />} onClick={closeModal} plain />
            </Box>
          )}
        </Box>
      )}
      {loading ? (
        <LoadingIndicator />
      ) : children}
    </Layer>
  );
}

Modal.propTypes = {
  background: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  hideClose: PropTypes.bool,
  loading: PropTypes.bool,
  onClickOutside: PropTypes.func,
  onClose: PropTypes.func,
  position: PropTypes.string,
};

export { Modal };
