import {
  INVALID_DOMAIN_FORMAT,
  INVALID_EMAIL_FORMAT,
  PASSWORD_ERROR,
  REQUIRED,
} from "../../components/utils/strings";
import { publicRuntimeConfig } from "@/lib/config";

const required = value => (value ? undefined : REQUIRED);

/**
 * Validate email format
 * @param {string} value - non-empty string of length > 0
 * @returns undefined if valid, error message if invalid
 */
const email = (value) => {
  if (!value) {
    // eslint-disable-next-line no-console
    console.warn("validate.email is not intended to test an empty string, make sure input length > 0");
  }

  // eslint-disable-next-line
  return value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(value) ? INVALID_EMAIL_FORMAT : undefined;
};

const slug = value => (
  // eslint-disable-next-line
  value && !/^[a-z]{1,}[a-z0-9-]{2,}$/
    .test(value) ? "Must consist of lower-cased letters or numbers, starting with a letter" : undefined
);

// Validate the profileId
// This is duplicated from 'validateUrlSafePath' on the API
const profileId = value => (
  // eslint-disable-next-line
  value && /[^a-zA-Z0-9-]+/
    .test(value) ? "Must consist of letters, numbers or -" : undefined
);

// Validate max length
// The value must be no more than the defined amount of characters
export const maxLength = length => value => (
  typeof value !== "string" || value.length > length
    ? `Must be no more than ${length} characters`
    : undefined
);

// Validate min length
// The value must be greater than or equal to the defined amount of characters
export const minLength = (length = 0) => value => (
  typeof value !== "string" || value.length < length
    ? `Must be at least ${length} characters`
    : undefined
);

/**
 * Password must be at least 8 characters, include at least 1 uppercase and 1 lowercase character, and include at least 1 number
 * Explaination of each of the capture groups
 *   ---
 *   (?=.*[a-z])    — Must contain a lowercase letter
 *   (?=.*[A-Z])    — Must contain an uppercase letter
 *   (?=.*\d)       — Must contain a number
 *   [a-zA-Z\d\w\W] — List of allowed characters (All uppercase & lowercase letters, numbers, "words" & "not words")
 *   {8,}           — Must contain at least 8 characters
 */
const password = value => (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(value)
  ? PASSWORD_ERROR
  : undefined);

// Validate a domain name
// This is a duplicated utility from the API
const validateDomainName = value => (value && !/^([a-zA-Z0-9-]+\.)+(([Xx][Nn]--)?[A-Za-z0-9-]{2,20})$/.test(value)
  ? INVALID_DOMAIN_FORMAT
  : undefined);

const validateUrl = value => value && !value.startsWith("http://") && !value.startsWith("https://")
  ? "Must start with http:// or https://"
  : undefined;

/**
 * Returns undefined (falsey) when ref URL is valid;
 * error string if not.
 */
export const validateRefUrl = (
  ref,
  hostname = publicRuntimeConfig.CLOVERLEAF_DOMAIN,
  subdomain = publicRuntimeConfig.CLOVERLEAF_SUBDOMAIN,
) => {
  if (!ref) {
    return "Invalid ref URL: empty.";
  }

  let protocol = "https:\\/\\/";

  if (subdomain === "www-local") {
    protocol = "http:\\/\\/";
  }

  const escapedHostname = hostname.replace(/\./g, "\\.");
  const escapedSubdomain = subdomain.replace(/\./g, "\\.");

  const port = `${publicRuntimeConfig.CLIENT_PORT}|${publicRuntimeConfig.API_PORT}`;

  const refUrlRegex =
    new RegExp(
      `^((${protocol})?(${escapedSubdomain}\\.)?${escapedHostname}(:(${port}))?)?` // optional host part
      + "(((?:\\/|%2F)[\\/\\w\\+@\\.%-]+)+((\\?[\\/\\w\\+@\\.%-]+=[\\/\\w\\+@\\.%-]+)([&\\?][\\/\\w\\+@\\.-]+=[\\/\\w\\+@\\.%-]+)*)?)?[\\?&]?$", // path with optional query parameters
      "i",
    );

  return (!refUrlRegex.test(ref))
    ? "Invalid ref URL. Must be a Cloverleaf path with optional query parameters."
    : undefined;
};

const validateAtLeastOneElement = value => (value.length > 0 ? undefined : REQUIRED);

export default {
  email,
  maxLength,
  minLength,
  password,
  profileId,
  required,
  slug,
  validateDomainName,
  validateRefUrl,
  validateUrl,
  validateAtLeastOneElement,
};
