import React from "react";
import styled from "styled-components";
import {
  Avatar as GrommetAvatar,
  Box,
  Stack,
} from "grommet";
import { normalizeColor } from "grommet/utils";
import { Camera } from "grommet-icons";
import { AvatarPropTypes } from "./propTypes";

const getUserInitials = (user) => {
  const { firstName, lastName, fullName, email } = user || {};

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }

  if (fullName) {
    const parts = fullName.split(" ");

    return getUserInitials({
      firstName: parts[0],
      lastName: parts[1] ?? undefined,
    });
  }

  return email ? email.charAt(0) : "";
};

const StyledAvatar = styled(GrommetAvatar)`
  text-transform: uppercase;
  ${props => props.disabled && "opacity: 0.75;"}
  ${props => props.onClick && `
    :hover {
      box-shadow: 0 0 0 1pt ${normalizeColor("brand", props.theme)};
    }
  `}
`;

const Avatar = React.forwardRef(({
  containerProps = {},
  disabled = false,
  // eslint-disable-next-line unused-imports/no-unused-vars
  icon = undefined,
  editing = false,
  onClick = undefined,
  skeleton,
  size = "medium",
  user = undefined,
  ...props
}, ref) => {
  let avatarProps = {
    allyTitle: user?.fullName,
    "data-testid": `avatar-${user?.id}`,
  };

  if (disabled) {
    avatarProps = {
      ...avatarProps,
      disabled: true,
      // overwrite onclick event
      onClick: undefined,
    };
  }

  if (user?.avatar) {
    avatarProps = {
      ...avatarProps,
      src: user.avatar,
    };
  }

  if (user && !user.avatar) {
    avatarProps = {
      ...avatarProps,
      children: getUserInitials(user),
    }
  }

  if (skeleton) {
    avatarProps = {};
  }

  return (
    <Stack
      ref={ref}
      anchor="bottom"
      alignSelf="center"
      interactiveChild="first"
      {...containerProps}
    >
      <StyledAvatar
        a11yTitle={user?.fullName}
        alignSelf="center"
        background="grey500"
        onClick={onClick}
        skeleton={skeleton}
        size={size}
        {...props}
        {...avatarProps}
      />
      {editing && (
        <Box pad={{ top: "xsmall" }}>
          <Camera color="white" size="small" />
        </Box>
      )}
    </Stack>
  );
});

Avatar.displayName = "Avatar";

Avatar.propTypes = AvatarPropTypes;

export { Avatar };
