import {
  Children,
  cloneElement,
  isValidElement,
  useContext
} from "react";
import { default as NextTrans } from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { EmployeeToolsContext } from "@/components/composite/EmployeeToolbox";

const Trans = ({ children, tOptions, ...props }) => {
  const { isAuditingI18n } = useContext(EmployeeToolsContext);

  if (isAuditingI18n) {
    const obfuscatedChildren = replaceTextWithXs(children);

    return <>{obfuscatedChildren}</>;
  }

  const components = Children.toArray(children);

  return <NextTrans components={components} values={tOptions} {...props} />;
};

function replaceTextWithXs(children) {
  return Children.map(children, (child) => {
    if (typeof child === "string") {
      const strs = child.split(" ");

      return strs.map(s => "x".repeat(s.length)).join(" ");
    } else if (isValidElement(child)) {
      return cloneElement(
        child,
        { ...child.props },
        replaceTextWithXs(child.props.children)
      );
    }

    return child;
  });
}

const useLocale = () => {
  const { isAuditingI18n } = useContext(EmployeeToolsContext);
  const { t, lang } = useTranslation();

  if (isAuditingI18n) {
    return {
      t: (key) => {
        const translatedString = t(key)

        const strs = translatedString.split(" ");

        return strs.map(s => "x".repeat(s.length)).join(" ");
      },
      lang,
    };
  }

  return { t, lang };
};

const serverSideTranslations = () => undefined;

export { serverSideTranslations, Trans, useLocale };
