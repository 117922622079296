import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import {
  Box,
  Button,
  Text,
} from "grommet";
import {
  Alert,
  CircleInformation,
  Info,
} from "grommet-icons";
import { Icon } from "@/cloverleaf-ui/components/atoms";
import { getThemeColor, themeStyle } from "@/cloverleaf-ui/theme";
import { useLocale } from "@/lib/hooks";

const messageKinds = {
  BANNER: "banner", /** @todo differentiate type from kind */
  OK: "ok",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

const kindToIconMap = {
  [messageKinds.OK]: Info,
  [messageKinds.INFO]: CircleInformation,
  [messageKinds.WARNING]: Alert,
  [messageKinds.ERROR]: CircleInformation,
};

function Message({
  children = undefined,
  contentGap = "medium",
  icon = undefined,
  label = undefined,
  link = undefined,
  kind = "ok",
  ...props
}) {
  const { t } = useLocale();

  const messageTheme = themeStyle.message?.kind[kind];

  let messageIcon;

  // Handle if icon is a React element, ex. font awesome Icon
  if (React.isValidElement(icon)) {
    messageIcon = React.cloneElement(icon, {
      color: messageTheme?.foreground,
      size: "sm", // font awesome icon size
      ...icon?.props,
    });
  }

  if (!messageIcon && kindToIconMap[kind]) {
    messageIcon = React.createElement(kindToIconMap[kind], {
      color: messageTheme?.foreground,
      size: "small",
    });
  }

  const { href, ...buttonProps } = link || {};

  let content = label || children;

  if (
    content
    && !Array.isArray(content)
    && typeof content !== "string"
    && !React.isValidElement(content)
  ) {
    try {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(content));
    }
    catch { /* swallow stringify */ }

    content = content?.message || "We're sorry, something went wrong.";
  }

  return (
    <Box
      align="center"
      background={{
        color: messageTheme?.background,
      }}
      color={messageTheme?.foreground}
      data-testid={`${kind}-message`}
      direction="row"
      justify="between"
      pad="medium"
      round="4px"
      width="full"
      {...props}
    >
      <Box
        align="center"
        justify="center"
        direction="row"
        gap={contentGap}
      >
        {messageIcon}
        <Text color={messageTheme?.foreground}>
          {content}
        </Text>
      </Box>
      <Box pad="small" />
      <Box direction="row-responsive" gap="small">
        <Link href="/account/settings/coaching-network" passHref legacyBehavior>
          <Button
            color="blue600"
            style={{
              // TODO: Remove default from theme.button, fix buttons
              // color: getThemeColor('text-coaching'),
              fontSize: "16px",
              borderRadius: "4px",
              padding: "8px 20px",
              textTransform: "uppercase",
            }}
            icon={<Icon color="blue600" icon={["far", "chart-network"]} />}
            label={t("your-coaching-network")}
            uppercase
            hoverIndicator={{
              background: "blue700",
            }}
            kind="coaching"
          />
        </Link>
        <Link href={href} passHref legacyBehavior>
          <Button
            color={messageTheme?.foreground}
            plain
            style={{
              // TODO: Remove default from theme.button, fix buttons
              color: getThemeColor(messageTheme?.foreground),
              fontSize: "16px",
              whiteSpace: "nowrap",
            }}
            {...buttonProps}
          />
        </Link>
      </Box>
    </Box>
  );
}

Message.propTypes = {
  children: PropTypes.node,
  contentGap: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  link: PropTypes.object,
  kind: PropTypes.oneOf(Object.values(messageKinds)),
};

export { Message };
