import { publicRuntimeConfig } from "@/lib/config";

export const parseSubdomain = (hostname) => {
  const parts = hostname ? hostname.split(".") : [];

  // If we are in development, we are flexible with parsing multiple subdomains
  if (publicRuntimeConfig.CLOVERLEAF_ENV !== "production") {
    return parts.length > 2 ? parts[0] : undefined;
  }

  if (parts.length === 3) return parts[0];

  return undefined;
};
