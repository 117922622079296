// eslint-disable-next-line no-undef
const pathname = typeof window !== "undefined" ? window.location.pathname : "";

let translations;
switch (true) {
  case pathname.includes("/es/"):
    translations = require("./translations/es_en");
    break;
  case pathname.includes("/de/"):
    translations = require("./translations/de_en");
    break;
  default:
    translations = require("./translations/en");
}

module.exports = translations;
