import { publicRuntimeConfig } from "./config";
import { sanitizeSearchParams } from "./sanitizeSearchParams";

function sanitizeUrl(url = "") {
  const baseUrl = typeof window !== "undefined" ? window.location.origin : publicRuntimeConfig.CLOVERLEAF_CLIENT_URL;

  const parsedUrl = new URL(url, baseUrl);

  parsedUrl.search = sanitizeSearchParams(parsedUrl.search);

  return parsedUrl.toString();
}

export { sanitizeUrl };
