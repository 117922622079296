import * as Sentry from "@sentry/nextjs";
import isEmpty from "lodash/fp/isEmpty";
import { destroyCookie } from "nookies";
import cacheInBrowser from "../components/utils/cacheInBrowser";
import redirect from "../components/utils/redirect";
import { boundSignout } from "../modules/boundActionCreators";
import { reset as clearAnalytics } from "./analytics";
import { publicRuntimeConfig } from "./config";
import { BASE_URL } from "./constants";

export const promiseToClearSession = async (apolloClient, ctx = {}) => {
  try {
    if (apolloClient) {
      await apolloClient.clearStore();
    }

    // Clear sessionStorage
    if (typeof window !== "undefined") {
      sessionStorage.clear();
      localStorage.clear();
    }

    // Clear cookies
    destroyCookie(null, publicRuntimeConfig.CLOVERLEAF_COOKIE_NAME, { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID, { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID, { domain: publicRuntimeConfig.CLOVERLEAF_DOMAIN });
    destroyCookie(null, "la_accounts", { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, "la_subdomain", { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, "la_state", { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });

    // Clear redux
    boundSignout();

    // Tell the server we're signing out, which will revoke our session, etc.
    return await fetch(`${BASE_URL}/api/user/logout`, {
      credentials: "include",
      method: "POST",
      headers: ctx.req ? ctx.req.headers : undefined, // if this is on the server, pass through headers
    });
  }
  catch (error) {
    Sentry.captureException(error, { extra: { message: "Error in clearSession", hasCtx: !isEmpty(ctx) } });
  }

  return Promise.resolve();
};

export const promiseToHandleSignout = ({ apolloClient, ctx = {} }) => {
  clearAnalytics();

  return promiseToClearSession(apolloClient, ctx);
};

// TODO: Deprecate these functions if possible or refactor duplicate code
export const clearSession = async (apolloClient, ctx = {}) => {
  try {
    if (apolloClient) {
      await apolloClient.clearStore();
    }

    // Clear sessionStorage
    cacheInBrowser.reset("importOrganization");
    cacheInBrowser.reset("timezone");

    // Cleared sessionStorage?
    cacheInBrowser.reset("la_accounts");
    cacheInBrowser.reset("la_subdomain");

    // Clear cookies
    // Clear root domain cookie set in middleware
    destroyCookie(null, publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID);
    // destroyCookie(null, publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID, { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, publicRuntimeConfig.CLOVERLEAF_COOKIE_NAME, { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, "la_accounts", { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, "la_subdomain", { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });
    destroyCookie(null, "la_state", { domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN });

    // Tell the server we're signing out, which will revoke our session, etc.
    await fetch(`${BASE_URL}/api/user/logout`, {
      credentials: "include",
      method: "POST",
      headers: ctx.req ? ctx.req.headers : undefined, // if this is on the server, pass through headers
    });

    // Clear redux
    boundSignout();
  }
  catch (error) {
    Sentry.captureException(error, { extra: { message: "Error in clearSession", hasCtx: !isEmpty(ctx) } });
    throw error;
  }
};

const handleSignout = async (ctx = {}, apolloClient, redirectPath = "/signin") => {
  // Redirect to the signin page.
  await redirect(ctx, redirectPath);
  clearAnalytics();

  return clearSession(apolloClient, ctx);
};

export default handleSignout;
