import { useState, useContext } from "react";
import React from "react";
import { useRouter } from "next/router";
import {
  Avatar,
  Box,
  CheckBox,
  FormField,
  Text,
  TextInput,
  ResponsiveContext,
  Button,
  Layer,
  List,
} from "grommet";
import { FormClose, Search } from "grommet-icons";
import { Icon } from "@/cloverleaf-ui/components/atoms/Icon/Icon";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import { isDesktop } from "@/cloverleaf-ui/utils";
import { useRelayUserSearch } from "../../UserDirectory/hooks/useRelayUserSearch";
import { getUserInitials } from "@/components/utils";
import { EVENT } from "@/components/utils/constants";
import { track } from "@/lib";
import { useCanManageOrgs } from "@/lib/hooks/useCanManageOrgs";

function Suggestion({ user, showOrg }) {
  return (
    <Box align="center" direction="row" gap="medium" margin="small">
      <Avatar
        background="grey300"
        size="small"
        src={user?.avatar ? user?.avatar : undefined}
      >
        {!user?.avatar ? getUserInitials(user) : undefined}
      </Avatar>
      <Box>
        <Text>
          {user?.fullName}
        </Text>
        <Text
          skeleton={{ width: "small" }}
          size="small"
        >
          {user?.email}
        </Text>
        {showOrg && (
          <Text
            skeleton={{ width: "small" }}
            size="small"
          >
            {user?.organization?.name}
          </Text>
        )}
      </Box>
    </Box>
  );
}

function UniversalSearchInput() {
  const size = useContext(ResponsiveContext);
  const isMobile = !isDesktop(size);

  const { canManageOrgs } = useCanManageOrgs();
  const router = useRouter();

  const [options, setOptions] = useState([]);
  const [filterByText, setFilterByText] = useState("");
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const {
    search: _search,
  } = useRelayUserSearch({
    search: { text: filterByText },
    onCompleted: (data) => {
      setOptions(data?.relayUserSearch?.edges?.map(edge => edge?.node?.user) || []);
      filterByText.length > 0 && track(EVENT.UniversalSearchExecuted.name, { search: filterByText })
    },
  });

  const {
    search: crossOrgSearch,
  } = useRelayUserSearch({
    crossOrgSearch: true,
    search: { text: filterByText },
    onCompleted: (data) => {
      setOptions(data?.crossOrgRelayUserSearch?.edges?.map(edge => edge?.node?.user) || []);
      filterByText.length > 0 && track(EVENT.UniversalSearchExecuted.name, { search: filterByText })
    },
    skip: !showAdvancedSearch,
  });

  const search = showAdvancedSearch ? crossOrgSearch : _search;

  const onSearch = (text) => {
    setFilterByText(text);

    return search({ text });
  };

  return (
    <Box flex={false} height={{ min: isMobile ? "medium" : undefined }}>
      <FormField
        label={isMobile ? "Search" : undefined}
        margin={isMobile ? "medium" : "0px"}
      >
        <Box direction="row">
          <TextInput
            onSuggestionsOpen={() => track(EVENT.UniversalSearchDropdownOpened.name)}
            value={filterByText}
            icon={<Search size="small" />}
            type="search"
            width={{ min: "220px" }}
            plain
            placeholder="Search by name or email"
            dropHeight="medium"
            suggestions={isMobile ? undefined : options.map(option => ({
              label: (
                <Suggestion
                  showOrg={canManageOrgs && showAdvancedSearch}
                  user={option}
                />
              ),
              value: { user: option },
            }))}
            onSuggestionSelect={
              ({ suggestion }) => {
                track(EVENT.UniversalSearchOptionClicked.name, {
                  profileUserId: suggestion.value.user.id,
                });

                return router.push(`/p/${suggestion.value.user.profile.id}`);
              }
            }
            onChange={event => onSearch(event.target.value)}
            role="search"
          />
          {canManageOrgs && (
            <Box
              border={{ side: "left", color: "border" }}
              width={{ min: "150px" }}
              direction="row"
              gap="small"
              pad={{ horizontal: "small" }}
              alignSelf="center"
            >
              <Box direction="row" gap="xxsmall" align="center">
                <Text
                  style={{ whiteSpace: "nowrap" }}
                >
                All orgs
                </Text>
                <Icon
                  icon={["far", "question-circle"]}
                  tip={{
                    message: (
                      <Box width={{ max: "300px" }}>
                        <Text>Include engagements and trial organizations in search</Text>
                      </Box>
                    ),
                    dropProps: { align: DROP_POSITION.bottom },
                    position: "bottom",
                  }}
                />
              </Box>
              <CheckBox
                checked={showAdvancedSearch}
                onChange={() => {
                  track(EVENT.UniversalSearchToggled.name, {
                    show: !showAdvancedSearch,
                  });

                  return setShowAdvancedSearch(v => !v);
                }}
                toggle
              />
            </Box>
          )}
        </Box>
      </FormField>
      {isMobile && (
        <Box overflow="auto" height={{ max: "medium" }}>
          <List
            data={options}
            onClickItem={({ item }) => {
              console.log("item", item);
              router.push(`/p/${item.profile.id}`);
            }}
            defaultItemProps={{
              pad: "none",
            }}
          >
            {option => (
              <Suggestion
                showOrg={canManageOrgs && showAdvancedSearch}
                user={option}
              />
            )}
          </List>
        </Box>
      )}
    </Box>
  );
}

function UniversalSearch() {
  const size = useContext(ResponsiveContext);
  const [showSearchModal, setShowSearchModal] = useState(false);

  if (!isDesktop(size)) {
    return (
      <>
        <Button
          icon={<Search />}
          plain
          onClick={() => setShowSearchModal(true)}
        />
        {showSearchModal && (
          <Layer
            show={showSearchModal}
            onClose={() => setShowSearchModal(false)}
            height={{ max: "400px" }}
            modal={true}
          >
            <Box align="end" pad="xsmall">
              <Button icon={<FormClose size="medium" />} onClick={() => setShowSearchModal(false)} plain />
            </Box>
            <UniversalSearchInput />
          </Layer>
        )}
      </>
    );
  }

  return (
    <UniversalSearchInput />
  );
}

export { UniversalSearch, UniversalSearchInput };
